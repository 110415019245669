<template>
  <div class="h-100">
    <div class="h-100" style="display: flex; flex-direction: column">
      <div
        class="d-flex align-items-center justify-content-between"
        style="padding: 0 2% 0 1.5%"
      >
        <p class="m-0 TitleTop">Product Match</p>
        <div class="d-flex align-items-center">
          <svg
            width="94"
            height="60"
            viewBox="0 0 94 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_500_7382)">
              <rect width="94" height="60" rx="8" fill="white" />
              <rect x="4" y="4" width="26" height="66" rx="4" fill="#EEEEEF" />
              <rect x="34" y="4" width="26" height="66" rx="4" fill="#EEEEEF" />
              <rect x="64" y="4" width="26" height="66" rx="4" fill="#EEEEEF" />
              <path
                d="M29.1667 34.0001L20.8333 34.0001M29.1667 34.0001L25.8333 37.3334M29.1667 34.0001L25.8333 30.6667"
                stroke="#FEB44E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M59.1667 34.0001L50.8333 34.0001M59.1667 34.0001L55.8333 37.3334M59.1667 34.0001L55.8333 30.6667"
                stroke="#FEB44E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M89.1667 34.0001L80.8333 34.0001M89.1667 34.0001L85.8333 37.3334M89.1667 34.0001L85.8333 30.6667"
                stroke="#FEB44E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <rect
              x="0.5"
              y="0.5"
              width="93"
              height="59"
              rx="7.5"
              stroke="#EAEAEA"
            />
            <defs>
              <clipPath id="clip0_500_7382">
                <rect width="94" height="60" rx="8" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <div class="d-flex flex-column ml-2">
            <p
              class="m-0"
              style="
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                color: #000;
              "
            >
              How to use product match?
            </p>
            <a
              href="https://support.quizell.com/blogs/create-a-quiz/how-our-product-match-works"
              target="_blank"
              class="helpLinkStyles mt-1"
              >Visit out help center</a
            >
          </div>
        </div>
      </div>

      <div
        class="flex-grow-1"
        style="
          padding: 11px 2% 0px 1.5%;
          max-height: calc(100vh - 215px);
          overflow-y: scroll;
          overflow-x: hidden;
        "
      >
        <div
          :class="[isLoadingFirstTime ? 'pointer-event-none' : null]"
          style="
            border-radius: 16px;
            border: 1px solid #ededed;
            background: #fff;
          "
        >
          <div
            class="row questions-not-font flex-column h-100 d-flex justify-content-center align-items-center"
            v-if="QuestionsList < 1 && isLoadingFirstTime == false"
          >
            <LazyImage
              :src="`https://images.quizell.com/website/404.png`"
              style="width: 30vw"
            />
            <div class="row text-center px-2 mt-5">
              <div class="col-12">
                <h3 class="noQuesFound">No Questions Found</h3>
              </div>
              <div class="col-12 pt-3">
                <h5 class="noQuesFound" style="font-size: 19px">
                  Please
                  <router-link
                    :to="`/customize/create/${getRouteId}`"
                    class="RouteLinkStyle"
                    >Create Questions</router-link
                  >
                  to match products
                </h5>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <!-- Questions Started -->
            <div class="col-3 pr-0">
              <div
                style="
                  border-top-left-radius: 16px;
                  border-bottom: 1px solid #ededed;
                  background: #f9f8f9;
                  border-right: 1px solid #ededed;
                  padding: 12px 12px;
                  height: 60px;
                "
              >
                <h5 class="sectionTitle">Question:</h5>
              </div>
              <div
                style="
                  height: 100%;
                  max-height: 65vh;
                  overflow: scroll;
                  border-right: 1px solid #ededed;
                  padding: 2px 5px;
                "
              >
                <div v-if="isLoadingFirstTime">
                  <div
                    class="d-flex justify-content-between px-3 options py-3"
                    v-for="index in 5"
                    :key="index"
                  >
                    <b-skeleton class="w-100" type="input"></b-skeleton>
                  </div>
                </div>
                <div
                  v-else
                  v-for="(question, index) in QuestionsList"
                  @click="selectQuestion(question, index)"
                  :key="question.id"
                  class="w-100 px-3 my-2"
                  style="cursor: pointer"
                  :class="
                    index == selected.questionIndex ? 'selectedDivHover' : null
                  "
                >
                  <div class="px-2 py-2 d-flex align-items-center">
                    <div
                      class="d-flex align-items-center"
                      :style="
                        index == selected.questionIndex
                          ? 'flex:0.9;'
                          : 'flex:1;'
                      "
                    >
                      <div
                        class="Order d-flex align-items-center justify-content-center mr-3"
                      >
                        <span> {{ index + 1 }}</span>
                      </div>
                      <div>
                        <span
                          style="
                            color: #1f1f28;
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                          "
                          >{{ question.name }}</span
                        >
                      </div>
                    </div>
                    <div
                      v-if="index == selected.questionIndex"
                      style="flex: 0.1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M14.1667 10.0001L5.83334 10.0001M14.1667 10.0001L10.8333 13.3334M14.1667 10.0001L10.8333 6.66675"
                          stroke="#4D1B7E"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Question End -->
            <!-- Options Started -->
            <!-- background: #b3afb61a -->
            <div class="col-3 px-0">
              <div
                class="d-flex align-items-center justify-content-between"
                style="
                  border-bottom: 1px solid #ededed;
                  background: #f9f8f9;
                  border-right: 1px solid #ededed;
                  padding: 12px 12px;
                  height: 60px;
                "
              >
                <h5 class="sectionTitle" style="color: #18191c">Options:</h5>
                <div
                  class="btn"
                  :disabled="isLoading"
                  @click="getProductData()"
                >
                  <i
                    :class="[isLoading ? 'refresh' : '', 'fa fa-refresh']"
                    v-b-tooltip.hover.bottom
                    title="Refresh"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>

              <div
                class="w-100"
                style="height: 65vh; overflow: scroll; padding: 2px 5px 0px"
              >
                <div class="Options p-0">
                  <div class="w-100 singleOption mt-2 mb-3">
                    <div v-if="isLoadingFirstTime">
                      <div
                        class="d-flex justify-content-between align-items-center px-3 options py-3"
                        v-for="index in 5"
                        :key="index"
                      >
                        <b-skeleton class="w-100" type="input"></b-skeleton>
                      </div>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-content-between align-items-center px-3 options py-3"
                      :class="[
                        selected.optionIndex == index ? 'selectedDivHover' : '',
                        'd-flex justify-content-between align-items-center px-3 options py-3',
                      ]"
                      v-for="(options, index) in selected.questionOptions"
                      :key="options.id"
                      @click="selectOption(index)"
                    >
                      <div
                        class="d-flex align-items-center"
                        :style="
                          selected.optionIndex == index
                            ? 'flex:0.9;'
                            : 'flex:1;'
                        "
                      >
                        <div
                          style="
                            color: #1f1f28;
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                          "
                        >
                          <span> {{ options.option_value }}</span>
                        </div>
                        <div
                          class="Option-order ml-3 d-flex align-items-center justify-content-center"
                          :style="
                            selected.optionIndex == index
                              ? 'background:#FEB44E;color:#ffffff;'
                              : null
                          "
                        >
                          {{ mapCategoryCount(index) }}
                        </div>
                      </div>
                      <div
                        v-if="selected.optionIndex == index"
                        style="flex: 0.1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.1667 10.0001L5.83334 10.0001M14.1667 10.0001L10.8333 13.3334M14.1667 10.0001L10.8333 6.66675"
                            stroke="#4D1B7E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>

                      <!-- <div class="order-info d-flex align-items-center">
                       
                        <div class="Option-order">
                         
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Options Ended -->
            <!-- Products components Started -->
            <section class="col-6 pl-0">
              <div
                class="justify-content-center d-flex align-items-center w-100"
                style="
                  border-top-right-radius: 16px;
                  border-bottom: 1px solid #ededed;
                  background: #f9f8f9;
                  border-right: 1px solid #ededed;
                  padding: 12px 12px;
                  height: 60px;
                "
              >
                <div
                  style="
                    display: flex;
                    padding: 4px;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    border-radius: var(--Elements-30-33px, 8px);
                    border: 1px solid rgba(24, 25, 28, 0.08);
                    background: #fff;
                  "
                >
                  <div
                    @click="OpenConfirmModal(button)"
                    v-for="button in actionBtns"
                    :key="button"
                    class="btn text-capitalize"
                    style="
                      color: var(--Gray-500, #667085);
                      font-family: Poppins;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                    "
                    :style="
                      button == activeCategory
                        ? `border-radius:6px;background: #0397CC;color:#fff;display: flex;
padding: 4px 8px;
justify-content: center;
align-items: center;
gap: var(--Elements-30-33px, 8px);`
                        : null
                    "
                  >
                    {{ button == "products" ? "Products" : button }}
                  </div>
                </div>
              </div>
              <Products
                :productStoreMapStatus="productStoreMapStatus"
                v-if="activeCategory == 'products' && !isLoadingFirstTime"
                ref="products"
                :isLoadingProducts="isLoadingFirstTime"
                :products="products"
                :TagsList="TagsList.all"
                :CollectionList="CollectionList.all"
                :VendorList="VendorList"
                :selected="selected"
                :question="QuestionsList[selected.questionIndex]"
                @importItems="importItems"
              />
              <div class="w-100">
                <Collections
                  v-if="activeCategory == 'collections'"
                  :products="products.all"
                  :isLoadingFirstTime="isLoadingFirstTime"
                  :collections="CollectionList"
                  :selected="selected"
                  :question="QuestionsList[selected.questionIndex]"
                  @importItems="importItems"
                ></Collections>
                <Tags
                  v-if="activeCategory == 'tags'"
                  :products="products.all"
                  :isLoadingFirstTime="isLoadingFirstTime"
                  :tags="TagsList"
                  :selected="selected"
                  :question="QuestionsList[selected.questionIndex]"
                  @importItems="importItems"
                ></Tags>
                <Vendors
                  v-if="activeCategory == 'vendors'"
                  :products="products.all"
                  :isLoadingFirstTime="isLoadingFirstTime"
                  :vendors="VendorList"
                  :selected="selected"
                  :question="QuestionsList[selected.questionIndex]"
                  @importItems="importItems"
                ></Vendors>
              </div>
              <b-modal id="confirmModal" title="Change Match">
                <div class="d-flex justify-content-center align-items-center">
                  <i
                    class="fa fa-info-circle mr-4"
                    aria-hidden="true"
                    style="color: #4d1b7e"
                  ></i>
                  If you want to change from {{ activeCategory }} to other, the
                  previous match will be removed.
                </div>
                <template #modal-footer>
                  <div class="w-100 d-flex justify-content-end">
                    <div class="modal-b">
                      <b-button
                        size="sm"
                        @click="ModalActions(false)"
                        class="mr-2"
                        ><span>Cancel</span></b-button
                      >
                      <b-button
                        size="sm"
                        @click="ModalActions(true)"
                        style="background: #4d1b7e"
                        ><span>OK</span></b-button
                      >
                    </div>
                  </div>
                </template>
              </b-modal>
            </section>

            <!-- Save changes Modal start -->

            <div
              class="modal fade"
              id="SaveConfirmModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              ref="SaveConfirmModal"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content SaveConfirmModalContent">
                  <div class="container">
                    <div>
                      <div class="container py-3 h-100">
                        <div class="">
                          <div class="row mb-5">
                            <div
                              class="w-100 d-flex flex-column justify-content-between align-items-center"
                            >
                              <p class="SaveConfirmModalTitle">
                                Changes not saved!
                              </p>
                              <p
                                class="SaveConfirmModalTitleSub text-center m-0"
                              >
                                Please remember to save each question before
                                moving on to a new one.
                              </p>
                              <p class="SaveConfirmModalTitleSub text-center">
                                This will ensure that your questions are
                                properly saved and prevent any loss of data.
                              </p>
                              <div
                                class="d-flex align-items-center w-100 justify-content-center"
                              >
                                <button
                                  @click="CloseSaveChangesModal"
                                  class="outline-none text-white outline-none border-0 px-3 py-2 see-demo-btn"
                                  style="
                                    background: #4d1b7eaa;
                                    border-radius: 6px;
                                  "
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="p-2 d-flex justify-content-end"
        style="
          border-top: 1px solid #eaecf0;
          background: #fff;
          box-shadow: 0px -4px 11px 0px rgba(31, 31, 40, 0.07);
        "
      >
      <button
          class="btn text-white mx-2 mt-2"
          style="
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            border-radius: 6px;
            background: #4d1b7e;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          "
          @click="saveData()"
          :class="saveLoading ? 'disableSaveBtn' : null"
        >
          <span v-if="!saveLoading"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M14.697 8.03033C14.9899 7.73744 14.9899 7.26256 14.697 6.96967C14.4041 6.67678 13.9292 6.67678 13.6363 6.96967L14.697 8.03033ZM8.33332 13.3333L7.80299 13.8637C8.09588 14.1566 8.57075 14.1566 8.86365 13.8637L8.33332 13.3333ZM6.36361 10.303C6.07072 10.0101 5.59584 10.0101 5.30295 10.303C5.01006 10.5959 5.01006 11.0708 5.30295 11.3637L6.36361 10.303ZM13.6363 6.96967L7.80299 12.803L8.86365 13.8637L14.697 8.03033L13.6363 6.96967ZM5.30295 11.3637L7.80299 13.8637L8.86365 12.803L6.36361 10.303L5.30295 11.3637ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25Z" fill="white"/>
</svg>  Save Changes</span>
          <div v-else class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import Products from "./ProductMatch/products.vue";
import Collections from "./ProductMatch/Collections.vue";
import Tags from "./ProductMatch/Tags.vue";
import Vendors from "./ProductMatch/Vendors.vue";
import { mapGetters } from "vuex";
// import AIProductMapModal from "../productmap/AIProductMapModal.vue";
export default {
  components: {
    Products,
    Collections,
    Tags,
    Vendors,
    // AIProductMapModal,
  },
  data() {
    return {
      productStoreMapStatus: 0,
      isLoading: false,
      dontRemindAgain: false,
      saveLoading: false,
      quizID: null,
      QuestionsList: [],
      clickedCategory: null,
      isLoadingBtn: false,
      actionBtns: ["products", "collections", "tags", "vendors"],
      isLoadingFirstTime: true,
      selected: {
        questionIndex: 0,
        optionIndex: 0,
        questionOptions: null,
      },
      TagsList: {
        all: [],
        selected: [],
      },
      CollectionList: {
        all: [],
        selected: [],
      },
      VendorList: [],
      products: {
        selected: [],
        all: [],
      },

      initialProductsLength: 0,
    };
  },
  watch: {
    dontRemindAgain(newValue) {
      if (newValue) localStorage.setItem("dont_remind_me_change_match", true);
      else localStorage.removeItem("dont_remind_me_change_match");
    },
    //     getSelectedQuestion:{
    //         handler() {
    //     if(this.getSelectedQuestionOptionProducts.length)
    //     this.initialProductsLength = this.getSelectedQuestionOptionProducts.length;
    //   },
    //   immediate: true,
    //     }
  },

  computed: {
    getRouteId() {
      return this.$route.params.id;
    },
    ...mapGetters([
      "getEditorSettings",
      "GetTokenFromMetaTag",
      "getQuizID",
      "getNavbarData",
    ]),
    questionList() {
      return this.QuestionsList;
    },
    isShopifyUser() {
      return this.getNavbarData.isShopifyUser
        ? this.getNavbarData.isShopifyUser
        : false;
    },
    isSaveBtnVisible() {
      let check = false;
      if (this.activeCategory == "products") {
        if (
          this.selected.questionIndex >= 0 &&
          this.selected.optionIndex >= 0 &&
          this.products.all.length > 0
        )
          check = true;
      }
      return check;
    },

    activeCategory: {
      get: function () {
        let map_category = "products";
        if (this.QuestionsList.length > 0) {
          // eslint-disable vue/no-side-effects-in-computed-properties
          map_category =
            this.QuestionsList[this.selected.questionIndex].options[
              this.selected.optionIndex
            ].map_category;
          let options =
            this.QuestionsList[this.selected.questionIndex].options[
              this.selected.optionIndex
            ];
          for (let index in options)
            if (index.includes("selected") && !index.includes(map_category)) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.QuestionsList[this.selected.questionIndex].options[
                this.selected.optionIndex
              ][index] = [];
            }
          map_category != "" ? map_category : "products";
        }
        return map_category;
      },
      set: function (newValue) {
        this.QuestionsList[this.selected.questionIndex].options[
          this.selected.optionIndex
        ].map_category = newValue;
      },
    },

    getSelectedQuestion() {
      if (this.QuestionsList.length && this.selected.questionIndex >= 0) {
        return this.selected.questionIndex;
      }
      return null;
    },
    getSelectedQuestionOptionProducts() {
      if (
        this.QuestionsList.length &&
        this.selected.optionIndex >= 0 &&
        this.selected.questionIndex >= 0
      ) {
        // return this.QuestionsList[this.selected.questionIndex].options[this.selected.optionIndex]
        if (this.activeCategory == "products") {
          return this.QuestionsList[this.selected.questionIndex].options[
            this.selected.optionIndex
          ].selected_products;
        }
        if (this.activeCategory == "collections") {
          return this.QuestionsList[this.selected.questionIndex].options[
            this.selected.optionIndex
          ].selected_collections;
        }
        if (this.activeCategory == "tags") {
          return this.QuestionsList[this.selected.questionIndex].options[
            this.selected.optionIndex
          ].selected_tags;
        }
        if (this.activeCategory == "vendors") {
          return this.QuestionsList[this.selected.questionIndex].options[
            this.selected.optionIndex
          ].selected_vendors;
        }
        return null;
      }
      return null;
    },

    productsLengthChanged() {
      return (
        this.initialProductsLength !==
        this.getSelectedQuestionOptionProducts.length
      );
    },
  },

  mounted() {
    this.getProductData();
    // setTimeout(() => {
    //     this.getProductData()
    // }, 1000)
  },
  methods: {
    CloseSaveChangesModal() {
      $("#SaveConfirmModal").modal("hide");
    },
    mapCategoryCount(optionIndex) {
      let count = 0;
      let category =
        this.QuestionsList[this.selected.questionIndex].options[optionIndex]
          .map_category;
      for (let index in this.QuestionsList[this.selected.questionIndex].options[
        optionIndex
      ]) {
        if (index.includes(category)) {
          count =
            this.QuestionsList[this.selected.questionIndex].options[
              optionIndex
            ][index].length;
          break;
        }
      }
      return count;
    },
    ModalActions(ok) {
      if (ok) this.activeCategory = this.clickedCategory;
      this.$bvModal.hide("confirmModal");
    },
    OpenConfirmModal(button) {
      if (!localStorage.getItem("dont_remind_me_change_match")) {
        this.clickedCategory = button;
        if (button !== this.activeCategory) this.$bvModal.show("confirmModal");
        else {
          this.activeCategory = button;
        }
      } else this.activeCategory = button;
    },
    async saveCollectionOptionProducts(selected_collections) {
      return axios
        .post("saveCollectionOptionProducts", {
          selected_collections: selected_collections,
          quizId: parseInt(localStorage.getItem("QuizID")),
        })
        .then((resp) => {
          if (resp.status == 200) return resp.data;
          return false;
        })
        .catch(() => false);
    },
    async selectedProductByCategory() {
      const selectedQuestion = this.QuestionsList[this.selected.questionIndex];

      // Create promises array to handle asynchronous operations
      const promises = selectedQuestion.options.map(async (option, index) => {
        let SelectedTags = [];
        let SelectedVendors = [];
        let selectedTagProducts = [];
        let selectedVendorProducts = [];

        if (option.map_category == "tags") {
          // Filter selected tags
          SelectedTags = this.TagsList.all.filter((tag) =>
            option.selected_tags.some(
              (selectedTag) => selectedTag.product_tag_id === tag.id
            )
          );

          // Map products to selected tags
          selectedTagProducts = this.products.all
            .filter((product) => product.tags && product.tags.length)
            .reduce((acc, product) => {
              if (
                SelectedTags.some(
                  (tag) => tag.title && product.tags.includes(tag.title)
                )
              ) {
                acc.push({
                  product_id: product.id,
                  question_option_id: option.id,
                });
              }
              return acc;
            }, []);

          selectedQuestion.options[index].selected_products =
            selectedTagProducts;
        } else if (option.map_category == "vendors") {
          // Filter selected vendors
          SelectedVendors = this.VendorList.filter((vendor) =>
            option.selected_vendors.some(
              (selectedVendor) => selectedVendor.product_vendor_id === vendor.id
            )
          );

          // Map products to selected vendors
          selectedVendorProducts = this.products.all
            .filter((product) => product.vendor != null)
            .reduce((acc, product) => {
              if (
                SelectedVendors.some(
                  (vendor) => vendor.title && vendor.title === product.vendor
                )
              ) {
                acc.push({
                  product_id: product.id,
                  question_option_id: option.id,
                });
              }
              return acc;
            }, []);

          selectedQuestion.options[index].selected_products =
            selectedVendorProducts;
        }
      });

      // Wait for all promises to resolve
      await Promise.all(promises);
    },

    async saveData() {
      if (this.saveLoading) {
        return;
      } else {
        this.saveLoading = true;
        await this.selectedProductByCategory();

        const selectedQuestion =
          this.QuestionsList[this.selected.questionIndex];
        try {
          let data = {
            mapQuestions: JSON.stringify(selectedQuestion),
            _token: this.GetTokenFromMetaTag,
            quizId: this.getQuizID,
          };
          const response = await axios.post(`/saveProductMap`, data);
          if (response.status == 200) {
            this.$toasted.show("Product Match Saved", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
              action: {
                class: "customToast",
              },
            });

            this.initialProductsLength =
              this.getSelectedQuestionOptionProducts.length;
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }

          throw error;
        } finally {
          this.saveLoading = false;
        }
      }
    },
    selectedItemCount(option) {
      let count = 0;
      // const option = this.QuestionsList[this.selected.questionIndex].options[this.selected.optionIndex];
      if (option) {
        if (this.activeCategory == "products")
          count = option.selected_products.length;
        if (this.activeCategory == "collections")
          count = option.selected_collections.length;
        if (this.activeCategory == "tags")
          count = option.selected_product_tags.length;
      }
      return count;
    },

    async getProductData() {
      this.isLoading = true;

      let data = {
        _token: this.GetTokenFromMetaTag,
        // quizId: localStorage.getItem('QuizID') ?  localStorage.getItem('QuizID') : this.getQuizID
        quizId: this.getQuizID
          ? this.getQuizID
          : localStorage.getItem("QuizID"),
      };
      await axios
        .post(`/loadProductMap`, data)
        .then((response) => {
          if (response.status == 200) {
            this.QuestionsList = response.data.data.questions;
            this.products.all = response.data.data.products;
            this.TagsList.all = response.data.data.tagsList;
            this.CollectionList.all = response.data.data.collectionList;
            this.selected.questionOptions =
              this.QuestionsList[this.selected.questionIndex].options;
            this.VendorList = response.data.data.vendorList;
            this.productStoreMapStatus =
              response.data.data.product_score_map_status;
            if (
              this.QuestionsList.length &&
              this.QuestionsList[0].options.length
            ) {
              this.initialProductsLength =
                this.QuestionsList[0].options[0].selected_products.length;
            }
          } else
            this.$toasted.show("Error occurred ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
        })
        .catch((error) => {
          if (error) {
            this.$toasted.show("Error occurred ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
        });

      this.isLoadingFirstTime = false;
      this.isLoading = false;
    },

    async selectQuestion(question, index) {
      // if(this.productsLengthChanged){
      //  $("#SaveConfirmModal").modal("show");
      //     return true
      // }

      await this.saveData();

      this.selected.optionIndex = 0;
      this.selected.questionIndex = index;
      this.selected.questionOptions = question.options;
      this.initialProductsLength =
        this.getSelectedQuestionOptionProducts.length;
    },
    selectOption(index) {
      this.selected.optionIndex = index;
    },
    async importItems(e) {
      let copyAllItems = {
        saveCollections: 0,
        saveProducts: 0,
        saveProductTags: 0,
        saveProductVendors: 0,
        saveProductWithVariants: 0,
        allVariantStatus: 0,
        isJobQueue: false,
        quizId: parseInt(localStorage.getItem("QuizID")),
      };
      if (e.title == "saveProducts") copyAllItems["allVariantStatus"] = 1;
      copyAllItems[e.title] = 1;
      await axios
        .post("/copyAllShopifyProducts", copyAllItems)
        .then((resp) => {
          if (resp.status != 200) {
            this.$toasted.show("Error occurred ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
          if (e.title == "saveProductTags") {
            this.TagsList.all = resp.data.data.productTags;
          } else if (e.title == "saveCollections") {
            this.CollectionList.all = resp.data.data.collections;
          } else if (e.title == "saveProductVendors") {
            this.VendorList = resp.data.data.productVendors;
          } else if (e.title == "saveProducts") {
            this.products.all = resp.data.data.products;
          }
          this.$toasted.show("Imported Successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((error) => {
          if (error) {
            this.$toasted.show("Error occurred ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        });
      e.callBackFunction();
    },
  },
};
</script>
<style>
.TitleTop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 28px;
  color: #1f1f28;
}

.sectionTitle {
  color: #1f1f28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.56px;
}
.noQuesFound {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 21px;

  letter-spacing: 0.04em;

  color: #4d4950;
}

.RouteLinkStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 140%;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #ffc000;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4d1b7e;

  border-radius: 4px;
  background-color: #4d1b7e;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  color: #fff;
  border-color: #4d1b7e;
  border-radius: 4px;
  background-color: #4d1b7e;
}
.custom-checkbox
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #4d1b7e;

  background-color: #fff;
}

.category-btn:hover {
  color: rgb(77, 27, 126) !important;
}

.pointer-event-none {
  pointer-events: none;
}

.container {
  transition: 0.5s ease all;
}

.selectedProduct {
  border-radius: 12px;
  border: 1px solid #4d1b7e;
}

.Order {
  border: 1px solid #4d1b7e;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 12px;
  padding: 10px;
  font-weight: 500;
  color: #4d1b7e;
}

.Option-order {
  background: #f2f4f7;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 12px;
  padding: 10px;
  font-weight: 500;
  color: #667085;
}
.product-image {
  height: 50px;
  width: 50px;
  border-radius: 8px;
  object-fit: cover;
  background-size: cover;
}

.options {
  cursor: pointer;
}

.active-btn {
  font-weight: 600;
  color: black !important;
  border-bottom: 2px solid rgb(77, 27, 126) !important;
  border-radius: 0px !important;
}

.refresh {
  animation: refresh-animation 2s linear infinite;
}

@keyframes refresh-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.SaveConfirmModalContent {
  border-radius: 20px;
  text-align: left;
}
.SaveConfirmModalTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}

.SaveConfirmModal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}

.disableSaveBtn {
  opacity: 0.4;
  cursor: none;
}

.selectedDivHover {
  border-radius: 8px;
  background: #b3afb626;
}
</style>
